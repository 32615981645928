export enum MenuKeys {
  FirstItem = 'contact.first_contact',
  SecondItem = 'contact.schedule_a_meeting',
  ThirdItem = 'offer.identify_prospects_needs',
  FourthItem = 'offer.make_a_sales_offer',
  FifthItem = 'negotiate.make_a_sales_pitch',
  SixthItem = 'negotiate.discuss_pricing',
  SeventhItem = 'close.remove_doubts',
  EighthItem = 'close.persuade_to_buy',
}

export const MENU_LABELS = {
  [MenuKeys.FirstItem]: 'First contact',
  [MenuKeys.SecondItem]: 'Schedule a meeting',
  [MenuKeys.ThirdItem]: 'Identify prospect’s needs',
  [MenuKeys.FourthItem]: 'Make a sales offer',
  [MenuKeys.FifthItem]: 'Make a sales pitch',
  [MenuKeys.SixthItem]: 'Discuss pricing',
  [MenuKeys.SeventhItem]: 'Remove doubts',
  [MenuKeys.EighthItem]: 'Persuade to buy',
};
