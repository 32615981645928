import { Avatar, Button, Col, Input, Row } from 'antd';
import { Link } from 'react-router-dom';
import userImage1 from '../../../../assets/images/users/user-1.svg';
import { RiArrowRightSLine } from 'react-icons/ri';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../../services/auth';

export default function PersonasList({ personas }) {
  const [filteredPersonas, setFilteredPersonas] = useState(personas);
  const [value, setValue] = useState('');
  const iconRef = useRef(null);
  const { balance } = useSelector(userSelector)

  const handleChange = (e) => {
    setValue(e.target.value);

    iconRef.current.style.display = e.target.value.length > 0 ? 'block' : 'none';

    const newPersonas = personas.filter(persona => {
      return persona.name.toLowerCase().includes(e.target.value.toLowerCase());
    });

    setFilteredPersonas(newPersonas);
  };

  const handleClick = () => {
    setValue('');
    setFilteredPersonas(personas);
  };

  return (
    <>
      <Row className="hp-recognition-controls" align="middle" justify="space-between">
        <Col span={8} className="hp-input-search">
          <svg className="hp-input-search-icon" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z" fill="#C3C9CC"/>
          </svg>
          <Input placeholder="Search" onChange={handleChange} value={value} />
          <svg ref={iconRef} className="hp-input-search-close" onClick={handleClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM10 8.586L12.828 5.757L14.243 7.172L11.414 10L14.243 12.828L12.828 14.243L10 11.414L7.172 14.243L5.757 12.828L8.586 10L5.757 7.172L7.172 5.757L10 8.586Z" fill="#727C80"/>
          </svg>
        </Col>
        <div className="hp-controls-line" />
        <Col
          className="hp-any-contacts-block hp-my-0"
          xs={4}
          sm={4}
          md={4}
          lg={4}
        >
          <Link to="/pages/new-recognition">
            <Button
              type="primary"
              htmlType="button"
              disabled={balance === 0}
            >
              + New recognition
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="hp-recognition-person hp-mt-24">
        <Col span={24}>
          {filteredPersonas.map( persona => (
            <Link to={`/pages/recognition/${persona.id}`} key={persona.id} className="hp-d-block hp-transition hp-bg-color-black-0 hp-hover-bg-color-primary-4 hp-hover-bg-color-dark-primary">
              <Row key={persona.id} align="middle" >
                <div className="hp-recognition-person-img">
                  <Avatar
                    size={34}
                    src={persona.avatar ? persona.avatar : userImage1}
                    style={{ margin: '0 auto' }}
                  />
                </div>
                <Col flex="1" className="hp-recognition-person-name">
                  <Row key={persona.id} align="middle" justify="space-between">
                    <Col flex="0.9">
                      <h5 className="hp-mb-0">{persona.name}</h5>
                    </Col>
                    <RiArrowRightSLine color="#727C80" className="remix-icon" size={24} />
                  </Row>
                </Col>
              </Row>
            </Link>)
          )}
        </Col>
      </Row>
    </>
  );
}
