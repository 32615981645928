import React from "react";

import { Row, Col, Avatar } from "antd";

export default function UserIconItem(props) {
  return (
    
      <Row>
        <Col span={24}>
          {props.image && (
            <Avatar src={props.image} size={props.imageHeight && (props.imageHeight)} className="hp-m-auto" />
          )}
        </Col>
      </Row>
    
  );
}
