import React from 'react';
import { Redirect , RouteProps, Route } from 'react-router-dom';


type PrivateRouteProps = {
  authenticationPath: string;
} & RouteProps;

export const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({ authenticationPath, ...routeProps }): JSX.Element => {
  if(localStorage.getItem('token')) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to={{ pathname: authenticationPath }} />;
  }
};