import React from 'react';
import Icon from '@ant-design/icons';

import { IProps } from '../types';

import { ReactComponent as _Chat } from './chat.svg';
import { ReactComponent as _Calendar } from './calendar.svg';
import { ReactComponent as _Doubts } from './doubts.svg';
import { ReactComponent as _List } from './list.svg';
import { ReactComponent as _Offer } from './offer.svg';
import { ReactComponent as _Pitch } from './pitch.svg';
import { ReactComponent as _Pricing } from './pricing.svg';
import { ReactComponent as _Rocket } from './rocket.svg';


export const Chat: React.FC<IProps> = (props) => <Icon component={_Chat} {...props} />;
export const Calendar: React.FC<IProps> = (props) => <Icon component={_Calendar} {...props} />;
export const Doubts: React.FC<IProps> = (props) => <Icon component={_Doubts} {...props} />;
export const List: React.FC<IProps> = (props) => <Icon component={_List} {...props} />;
export const Offer: React.FC<IProps> = (props) => <Icon component={_Offer} {...props} />;
export const Pitch: React.FC<IProps> = (props) => <Icon component={_Pitch} {...props} />;
export const Pricing: React.FC<IProps> = (props) => <Icon component={_Pricing} {...props} />;
export const Rocket: React.FC<IProps> = (props) => <Icon component={_Rocket} {...props} />;
