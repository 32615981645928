import React from 'react'
import { Col, Row } from 'antd'

const year = new Date().getFullYear();

export const Footer = () => {
  return (
    <Row align="middle" justify="space-between" className="hp-new-footer">
      <Col>
        <Row align="middle" justify="center" gutter={[16]}>
          <Col>
            <a href="https://hrmny.business/privacy-policy" className="hp-new-footer-link hp-text-color-black-100 hp-text-color-dark-40">
              Privacy Policy
            </a>
          </Col>
          <Col>
            <a href="https://hrmny.business/terms-and-conditions" className="hp-new-footer-link hp-text-color-black-100 hp-text-color-dark-40">
              Term of use
            </a>
          </Col>
        </Row>
      </Col>
      <Col>
        <p className="hp-text-color-black-80 hp-text-color-dark-40 hp-mb-0">© {year} HRMNY</p>
      </Col>
    </Row>
  );
}
