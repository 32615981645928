import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

type Props = {
  personName?: string
  text?: string
}

const Spinner: React.FC<Props> = ({ personName, text }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 96 }} spin />;

  return (
    <div className="hp-d-flex-full-center hp-d-flex-column hp-mt-120">
      <Spin indicator={antIcon} />
      {personName && <h4 className="hp-mt-10 hp-mb-4">{personName}</h4>}
      <p>{text || 'Please wait. Processing data...'}</p>
    </div>
  );
};

export default Spinner;
