import React from 'react';
import {Col, Row} from "antd";
import { useSelector } from 'react-redux';
import useAnalyticsEventTracker from "../../../utils";
import { userSelector } from '../../../services/auth';
import HeaderUser from "./HeaderUser";
import logo from "../../../assets/images/logo/Logotype_main_header.svg";
import { Link } from 'react-router-dom';

export default function HeaderText({ user }) {
  const gaEventTrackerRecognition = useAnalyticsEventTracker('Recognition');
  const { balance } = useSelector(userSelector);

  const showPayment = () => {
    gaEventTrackerRecognition('buy_more')
  };

  return (
    <Row
      className={`hp-new-header hp-header-left-text hp-d-flex-center hp-d-flex-between ${user ? "hp-pl-24 hp-pr-24" : ""}`}
    >
      <div className=" hp-overflow-hidden hp-d-flex" style={{ minWidth: 32, width: 32, height: 32 }}>
        <img src={logo} alt="logo" style={{ marginTop: 'auto', marginLeft: 'auto' }} />
      </div>
      <Row className="hp-d-flex-center hp-d-flex-between">
        <Link to="/pages/checkout">
          <Col className="hp-balance-block" onClick={showPayment}>
            {balance !== undefined && <p className="hp-mb-0">{balance} {balance === 1 ? 'recognition' : 'recognitions'}</p>}
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9 9H5V11H9V15H11V11H15V9H11V5H9V9Z" fill="#0063F7"/>
            </svg>
          </Col>
        </Link>
        {user && <HeaderUser/>}
      </Row>
    </Row>
  );
};
