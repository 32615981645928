import { Grid5, Award } from 'iconsax-react';

import IntlMessages from "../../layout/components/lang/IntlMessages";

const userInterface = [
    {
        header: <IntlMessages id="sidebar-user-interface" />,
    },
    {
        id: "user-interface-typography",
        title: <IntlMessages id="sidebar-user-interface-typography" />,
        icon: <Grid5 size={18} />,
        navLink: "/components/general/style-guide",
    },
    {
        id: "user-interface-buttons",
        title: <IntlMessages id="sidebar-user-interface-buttons" />,
        icon: <Grid5 size={18} />,
        navLink: "/components/general/buttons",
    },
    {
        id: "user-interface-components",
        title: <IntlMessages id="sidebar-user-interface-components" />,
        icon: <Award size={18} />,
        navLink: "/components/components-page",
    },
    {
        id: "charts",
        title: <IntlMessages id="sidebar-user-interface-charts" />,
        icon: <Award size={18} />,
        navLink: "/main/widgets/charts",
    },
    {
        id: "selectbox",
        title: <IntlMessages id="sidebar-user-interface-selectbox" />,
        icon: <Award size={18} />,
        navLink: "/main/widgets/selectbox",
    },
    {
        id: "user-interface-icons",
        title: <IntlMessages id="sidebar-user-interface-icons" />,
        icon: <Award size={18} />,
        children: [
            {
                id: "user-interface-remix-icons",
                title: <IntlMessages id="sidebar-user-interface-remix-icons" />,
                navLink: "/components/general/icons",
            },
        
            {
                id: "user-icons",
                title: <IntlMessages id="sidebar-user-interface-user-icons" />,
                navLink: "/main/widgets/user-icons",
            },
            {
                id: "flags",
                title: <IntlMessages id="sidebar-user-interface-flags" />,
                navLink: "/main/widgets/flags",
            },
        ]
    },
    {
        id: "page-layouts",
        title: <IntlMessages id="sidebar-user-interface-layouts-page" />,
        icon: <Award size={18} />,
        children: [
            {
                id: "boxed-layout",
                title: <IntlMessages id="sidebar-user-interface-layouts-page-boxed" />,
                navLink: "/main/layouts/page-layouts/boxed-layout",
            },
            {
                id: "vertical-layout",
                title: <IntlMessages id="sidebar-user-interface-layouts-page-vertical" />,
                navLink: "/main/layouts/page-layouts/vertical-layout",
            },
            {
                id: "horizontal-layout",
                title: <IntlMessages id="sidebar-user-interface-layouts-page-horizontal" />,
                navLink: "/main/layouts/page-layouts/horizontal-layout",
            },
            {
                id: "full-layout",
                title: <IntlMessages id="sidebar-user-interface-layouts-page-full" />,
                navLink: "/main/layouts/page-layouts/full-layout",
            },
        ],
    },
    {
        id: "cards",
        title: <IntlMessages id="sidebar-user-interface-cards" />,
        icon: <Award size={18} />,
        children: [
            {
                id: "advance",
                title: <IntlMessages id="sidebar-user-interface-advance" />,
                navLink: "/main/widgets/cards/advance",
            },
            {
                id: "statistics",
                title: <IntlMessages id="sidebar-user-interface-statistics" />,
                navLink: "/main/widgets/cards/statistics",
            },
            {
                id: "widgets-analytics",
                title: <IntlMessages id="sidebar-user-interface-analytics" />,
                navLink: "/main/widgets/cards/analytics",
            },
        ],
    },
];

export default userInterface