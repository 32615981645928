import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import { SERVER } from '../constants';
import axios from 'axios';

interface Feedback {
    rate: number,
    id: number,
    own_version?: string,
  }

interface Persona {
    content: Object
}

interface Auth {
    postData: FormData
}

export const sendFeedback = createAsyncThunk<Feedback>(
    'sendFeedback',
    async ( feedback, thunkAPI) => {
      try {
        const id = thunkAPI.getState().personaItem.persona.id;
        const response = await axios(
          `https://${SERVER}/api/persona/${id}/feedback`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            data: feedback
          }
        );
        let data = await response.data;

          return data;
      } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
  export const getFeedback = createAsyncThunk<Feedback>(
    'getFeedback',
    async (id,thunkAPI) => {
      try {
        const response = await axios(
          `https://${SERVER}/api/persona/${id}/feedback`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          }
        );
        let data = await response.data;
          return data;
      } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
  export const getPersona = createAsyncThunk<Persona, Auth>(
    'getPersona',
    async (id, thunkAPI) => {

      try {
        const response = await axios(
          `https://${SERVER}/api/persona/${id}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          }
        );
        let data = await response.data;

        if (response.status === 200) {
          return data;
        }
        else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const personaItemSlice = createSlice({
    name: 'personaItem',
    initialState: {
      feedback: null,
      persona: {},
      isFetching: false,
      isSuccess: false,
      isTimer: false,
      isError: false,
      errorMessage: '',
      isPersonaSent: false,
    },
    reducers: {
      clearState: (state) => {
        state.isError = false;
        state.isSuccess = false;
        state.isFetching = false;
        state.feedback = null;
        state.persona = {};
        return state;
      },
      errorState: (state) => {
        state.isError = true;
        state.isSuccess = false;
        state.isFetching = false;
        state.isTimer = false;
        return state;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getPersona.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.persona = payload;
      })
      .addCase(getPersona.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getPersona.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(sendFeedback.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.feedback = payload;
      })
      .addCase(sendFeedback.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(sendFeedback.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getFeedback.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.feedback = payload;
      })
      .addCase(getFeedback.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getFeedback.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
      })
  },
  });

  export const { clearState, errorState } = personaItemSlice.actions;

  export const personaItemSelector = (state: RootState) => state.personaItem;
