import {
  Button,
  Col,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Slider,
} from 'antd';
import useAnalyticsEventTracker, { useDeviceType } from '../../../../utils';
import React, { useEffect, useState } from 'react';
import {
  checkout,
  clearState,
  errorState,
  getPersonas,
  getPrices,
  personaSelector,
} from '../../../../services/persona';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import { fetchUserBytoken, userSelector } from '../../../../services/auth';
import ReactGA from 'react-ga4';
import Pusher from 'pusher-js';
import { CURRENCY, PUSHER_APP_KEY, SERVER } from '../../../../constants';
import HeaderText from '../../../../layout/components/new-header/HeaderText';
import MarketingSubscription from '../../../components/subscription';
import { Footer } from '../../../../layout/components/new-footer';
import { RecognitionPageInfo } from '../info';
import Spinner from '../spinner';

const CheckoutPage = () => {
  const gaEventTrackerPayment = useAnalyticsEventTracker('Payment');
  const gaEventTrackerRecognition = useAnalyticsEventTracker('Recognition');
  const [currencyValue, setCurrencyValue] = useState('eur');
  const [inputValue, setInputValue] = useState(1);
  const [isMobile, isTablet] = useDeviceType();
  const history = useHistory();
  const { isFetching, prices, isPersonaSent } = useSelector(
    personaSelector
  );
  const dispatch = useDispatch();
  const { id, marketingPopup } = useSelector(userSelector)

  const onChange = (newValue: number) => {
    gaEventTrackerPayment('Choose_amount')
    setInputValue(newValue);
  };

  const checkoutFunc = () => {
    gaEventTrackerPayment('Checkout')
    dispatch(checkout({ quantity: inputValue, currencyValue }))
  }

  const radioOnChange = (e:RadioChangeEvent) => {
    setCurrencyValue(e.target.value)
  }

  const price = prices[currencyValue] && prices[currencyValue]['unit_amount'];

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    dispatch(clearState())
    dispatch(fetchUserBytoken())
    dispatch(getPersonas())
    dispatch(getPrices())
  },[]);

  useEffect(() => {
    if(isPersonaSent) {
      const pusher = new Pusher(PUSHER_APP_KEY, {
        cluster: 'eu',
        channelAuthorization: {
          endpoint: `https://${SERVER}/broadcasting/auth`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'application/json',
          }
        }
      });

      const channel = pusher.subscribe(`private-App.Models.User.${id}`);

      channel.bind('typingPersona', (dataChanel) => {
        if(dataChanel.typingPersona.process_state === 'done'){
          gaEventTrackerRecognition('wait_until_the_end_of_the_timer')
          history.push(`/pages/recognition/${dataChanel.typingPersona.id}`)
        } else {
          dispatch(errorState())
        }
      });
    }
  },[isPersonaSent])

  const payment = (
    <>
      {isTablet && (
        <>
          <Link to="/pages/recognition">
            <div className="hp-form-info-close-btn">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 10.5862L16.95 5.63623L18.364 7.05023L13.414 12.0002L18.364 16.9502L16.95 18.3642L12 13.4142L7.05 18.3642L5.636 16.9502L10.586 12.0002L5.636 7.05023L7.05 5.63623L12 10.5862Z" fill="#2D3436"/>
              </svg>
            </div>
          </Link>
          <h3 className="hp-form-info-title">Buy recognitions</h3>
        </>
      )}
      <Row className="hp-mt-sm-48 hp-mt-18 hp-mb-32">
        <Radio.Group onChange={radioOnChange} value={currencyValue}>
          {prices && Object.keys(prices).map(item => (<Radio key={item} value={item}>{item.toUpperCase()}</Radio>))}
        </Radio.Group>
      </Row>
      <Row>
        <Col
          xs={17}
          sm={17}
          md={17}
          lg={18}
        >
          <p className="hp-p1-body hp-font-weight-600">Choose amount</p>
          <Slider
            min={1}
            trackStyle={{background: '#775CFF'}}
            max={20}
            onChange={onChange}
            value={typeof inputValue === 'number' ? inputValue : 0}
          />
        </Col>
        <Col span={6}>
          <InputNumber
            className="hp-mt-18 hp-ml-8 input-field"
            min={1}
            controls={false}
            max={20}
            value={inputValue}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <p className="hp-mt-18 hp-mb-8 hp-p1-body hp-font-weight-700">Summary</p>
          <p className="hp-p1-body">{inputValue} recognitions <span className="hp-font-weight-600">x {price && `${CURRENCY[currencyValue]} ${(price).toFixed(2)}`}</span></p>
        </Col>
        <Col span={8}>
          <p className="hp-mt-46 hp-p1-body hp-text-center">{price && `${CURRENCY[currencyValue]} ${(inputValue*price).toFixed(2)}`}</p>
        </Col>
      </Row>
      <Row className="hp-bg-color-payment-2 hp-px-12 hp-mt-18 hp-py-8 hp-px-12">
        <Col span={16}>
          <p className="hp-p1-body hp-mb-0 hp-font-weight-700">Total to pay</p>
          <p className="hp-input-description hp-mb-0">(VAT included)</p>
        </Col>
        <Col span={8}>
          <h5 className="hp-font-weight-700 hp-mt-6 hp-text-center">{price && `${CURRENCY[currencyValue]} ${(inputValue*price).toFixed(2)}`}</h5>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button block type="primary" className="hp-bg-color-payment-1 hp-border-color-payment-1 hp-mt-18 hp-p1-body hp-font-weight-600" onClick={checkoutFunc}>
            Proceed to Checkout
          </Button>
        </Col>
      </Row>
    </>
  );
  return isFetching ? (
    <Spinner />
  ) : (
    <>
      {!isTablet && (
        <Row className="hp-recognition-page" justify="center">
          <Col xs={24}>
            <HeaderText user />
          </Col>
        </Row>
      )}
      {!isTablet ? (
        <Row justify="space-between" className="hp-recognition-form hp-pl-24 hp-pr-24" gutter={{ xs: 0, sm: 0, md: 37 }}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={18}
            className="hp-main-form"
          >
            <div className="hp-d-flex hp-align-items-center hp-mt-32 hp-mb-48 hp-mb-sm-42">
              <Link to="/pages/new-recognition">
                <div className="hp-arrow-back hp-d-flex">
                  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.10724 16L0.470881 8.36364L8.10724 0.727273L9.41974 2.02273L4.01634 7.42614H18.0107V9.30114H4.01634L9.41974 14.6875L8.10724 16Z" fill="#2D3436"/>
                  </svg>
                </div>
              </Link>
              <h3 className="hp-ml-16 hp-mb-0">Buy recognitions</h3>
            </div>
            <Row justify="center">
              <Col
                sm={24}
                xs={24}
                md={10}
                lg={8}
              >
                {payment}
              </Col>
            </Row>
          </Col>
          {!isTablet && <RecognitionPageInfo />}
        </Row>
      ) : (
        <Col className="hp-form-info">{payment}</Col>
      )}
      {!isTablet && (
        <Row justify="center">
          <Footer />
        </Row>
      )}
      {marketingPopup && <MarketingSubscription/>}
    </>
  );
}

export default CheckoutPage;
