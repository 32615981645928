import React, { useEffect, useState } from "react";
import Pusher from 'pusher-js';
import { Row, Col, Form, Input, Button, Upload, Radio } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import ReactGA from 'react-ga4';
import type { UploadProps } from 'antd/es/upload';
import { PlusOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import useAnalyticsEventTracker, { useDeviceType } from '../../../../utils';
import {
  personaSelector,
  errorState,
  sendPersona,
  sendLinkedinPersona,
  getPersonas,
  getPrices,
  clearState
} from '../../../../services/persona';
import { fetchUserBytoken, userSelector } from '../../../../services/auth';
import { PUSHER_APP_KEY, SERVER } from '../../../../constants';
import { RecognitionPageInfo } from "../info";
import { Footer } from '../../../../layout/components/new-footer';
import { ErrorInfo } from '../error';
import MarketingSubscription from "../../../components/subscription";
import HeaderText from "../../../../layout/components/new-header/HeaderText";
import Spinner from '../spinner';
import Loader from '../loader';

const NAME_MAX_LENGTH = 27;
const ERROR_NAME = {
  size: 'Maximum file size: 10 MB'
};
const LOADING_PHOTO = 'Please wait. Photo is loading...';

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload photo</div>
  </div>
);

const NewRecognitionPage = () => {
  const gaEventTrackerPayment = useAnalyticsEventTracker('Payment');
  const gaEventTrackerRecognition = useAnalyticsEventTracker('Recognition');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [linkedIn, setLinkedIn] = useState(false);
  const [linkedInProfileLink, setLinkedInProfileLink] = useState('');
  const [tabValue, setTabValue] = useState('1');
  const [requirementsModal, setRequirementsModal] = useState(false);
  const [personName, setPersonName] = useState('');
  const [isMobile, isTablet] = useDeviceType();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const { isError, errorMessage, errorType, isFetching, timerDelay, isTimer, personas, isPersonaSent, isSuccess } = useSelector(
    personaSelector
  );
  const { balance, id, marketingPopup } = useSelector(userSelector)

  const handlePhotoReplace = () => {
    const uploadElement = document.querySelector('.ant-upload-select-picture-card input');
    uploadElement?.dispatchEvent(new MouseEvent("click",{bubbles: true, cancelable: true}));
    uploadElement?.dispatchEvent(new TouchEvent("touchstart",{bubbles: true, cancelable: true}));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    gaEventTrackerRecognition('upload');
    setFileList(newFileList);
  }

  const handleChangeBefore: UploadProps['beforeUpload'] = (file) => {
    if (file.size > 10000000) {
      dispatch(errorState(ERROR_NAME.size));
      return true;
    }
    return false;
  };

  const handleRemove = () => {
    dispatch(errorState(''));
    dispatch(clearState());
  };

  const handleChangeTab = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTabValue(e.target.value);
    setLinkedIn((visible) => !visible);
  };

  const onFinish = (data) => {
    const postData = new FormData();
    if (linkedInProfileLink) {
      postData.append('profile_url', data.linkedIn)
      setLinkedInProfileLink('')
      dispatch(sendLinkedinPersona(postData))

    } else {
      setPersonName(data.name);

      data['file'].map((file, index) =>{
        postData.append(`file[${index}]`, file.originFileObj, file.name)
      });
      postData.append('name', data.name)
      setFileList([]);
      dispatch(sendPersona(postData))
    }

  };

  const handlePaymentClick = () => {
    gaEventTrackerRecognition('recognize')
    dispatch(clearState())
    if (balance === 0) history.push('pages/checkout');
  }

  const handleLinkedinProfileLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkedInProfileLink(e.target.value);
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    dispatch(clearState())
    dispatch(fetchUserBytoken())
    dispatch(getPersonas())
    dispatch(getPrices())
  },[]);

  useEffect(() => {
    if(isPersonaSent) {
      const pusher = new Pusher(PUSHER_APP_KEY, {
        cluster: 'eu',
        channelAuthorization: {
          endpoint: `https://${SERVER}/broadcasting/auth`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'application/json',
          }
        }
      });

      const channel = pusher.subscribe(`private-App.Models.User.${id}`);

      channel.bind('typingPersona', (dataChanel) => {
        if(dataChanel.typingPersona.process_state === 'done'){
          gaEventTrackerRecognition('wait_until_the_end_of_the_timer')
          history.push(`/pages/recognition/${dataChanel.typingPersona.id}`)
          history.go(0)
        } else {
          if (dataChanel.typingPersona.ai_errors.status === 403 || dataChanel.typingPersona.ai_errors.status === 404) {
            dispatch(errorState({message : dataChanel.typingPersona.ai_errors.message, type: 'linkedin'}))
          } else {
            dispatch(errorState(''))
          }

          fileList.length = 0;
          const invalidPhoto = document.querySelector<HTMLElement>('.ant-upload-list-picture-card-container');
          if (invalidPhoto) {
            invalidPhoto.style.display = "none";
          }
        }
      });
    }
  },[isPersonaSent])

  const changeRequirementsVisible = () => setRequirementsModal((visible) => !visible);

  return isFetching ? (
    <div className="hp-d-flex-full-center hp-d-flex-column hp-mt-120">
      {isSuccess
        ? <Loader sec={timerDelay} />
        : isTimer
          ? <Spinner personName={personName} text={LOADING_PHOTO} />
          : <Spinner personName={personName} />
      }
    </div>
  ) : isTablet && requirementsModal
    ? <RecognitionPageInfo onClose={changeRequirementsVisible} />
    : (
      <>
          <Row className="hp-recognition-page" justify="center">
            <Col xs={24}>
              <HeaderText user />
            </Col>
          </Row>
          <Row justify="space-between" className="hp-recognition-form hp-pl-24 hp-pr-24" gutter={{ xs: 0, sm: 0, md: 37 }}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={18}
              className="hp-main-form"
            >
              <div className="hp-d-flex hp-align-items-center hp-mt-32 hp-mb-48 hp-mb-sm-42">
                <Link to="/pages/recognition">
                  <div className="hp-arrow-back hp-d-flex">
                    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.10724 16L0.470881 8.36364L8.10724 0.727273L9.41974 2.02273L4.01634 7.42614H18.0107V9.30114H4.01634L9.41974 14.6875L8.10724 16Z" fill="#2D3436"/>
                    </svg>
                  </div>
                </Link>
                <h3 className="hp-ml-16 hp-mb-0">{isMobile ? 'New recognition' : 'New contact recognition'}</h3>
              </div>
              <Row justify="center">
                <Col
                  sm={24}
                  xs={24}
                  md={10}
                  lg={8}
                >
                  <Radio.Group onChange={handleChangeTab} value={tabValue}>
                    <Radio key={1} value={'1'}>Photo upload</Radio>
                    <Radio key={2} value={'2'}>LinkedIn profile</Radio>
                  </Radio.Group>
                  <Form
                    form={form}
                    layout="vertical"
                    name="basic"
                    className="hp-mt-sm-16 hp-mt-32"
                    onFinish={onFinish}
                  >
                    {!linkedIn ? (
                      <>
                      <p className="hp-photo-caption hp-mb-24">
                        File size must not exceed 20Mb. {isTablet && (
                        <span>See <span onClick={changeRequirementsVisible} className="hp-requirements-btn">Requirements</span></span>
                      )}
                      </p>
                      <Row align="middle">
                        <Col>
                          <Form.Item
                            name="file"
                            label="Upload person photo"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            className={isError && errorType === 'photo' ? 'hp-mb-36' : 'hp-mb-lg-10 hp-mb-24'}
                          >
                            <Upload
                              action='images/*'
                              listType="picture-card"
                              className="avatar-uploader"
                              fileList={fileList}
                              beforeUpload={handleChangeBefore}
                              onChange={handleChange}
                              onRemove={handleRemove}
                              maxCount={1}
                              showUploadList={{ showPreviewIcon:false }}
                              accept="image/jpeg, image/jpg, image/png"
                            >
                              {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                          </Form.Item>
                          </Col>
                          {fileList.length >= 1 && (
                            <Col
                              onClick={handlePhotoReplace}
                              className="hp-replace-photo-block hp-d-flex-full-center hp-d-flex-column hp-ml-32"
                            >
                              <svg className="hp-mb-8" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.463 4.433C7.27756 2.86066 9.59899 1.99665 12 2C17.523 2 22 6.477 22 12C22 14.136 21.33 16.116 20.19 17.74L17 12H20C20.0001 10.4316 19.5392 8.89781 18.6747 7.58926C17.8101 6.28071 16.5799 5.25516 15.1372 4.64013C13.6944 4.02509 12.1027 3.8477 10.56 4.13002C9.0172 4.41234 7.59145 5.1419 6.46 6.228L5.463 4.433ZM18.537 19.567C16.7224 21.1393 14.401 22.0034 12 22C6.477 22 2 17.523 2 12C2 9.864 2.67 7.884 3.81 6.26L7 12H4C3.99987 13.5684 4.46075 15.1022 5.32534 16.4107C6.18992 17.7193 7.42007 18.7448 8.86282 19.3599C10.3056 19.9749 11.8973 20.1523 13.44 19.87C14.9828 19.5877 16.4085 18.8581 17.54 17.772L18.537 19.567Z" fill="#0063F7"/>
                              </svg>
                              Replace photo
                            </Col>
                          )}
                        </Row>

                        {isError && errorType === 'photo' && <ErrorInfo
                          message={errorMessage}
                          className="hp-mb-lg-24 hp-mb-32"
                        />}
                        {personas && (
                          <Form.Item className="hp-mb-32" label="Person name" name="name" initialValue={`Person ${personas.length}`}>
                            <Input
                              id="error"
                              onChange={()=> gaEventTrackerPayment('person_name')}
                              maxLength={NAME_MAX_LENGTH}
                            />
                          </Form.Item>
                        )}
                      </>
                    ) : (
                      <Form.Item
                        name="linkedIn"
                        label="Person LinkedIn profile link"
                        className={isError && linkedIn && errorType === 'linkedin' ? 'hp-mb-10' : 'hp-mb-32'}
                      >
                        <Input
                            id="linkedIn"
                            placeholder="https://"
                            onChange={handleLinkedinProfileLinkChange}
                        />
                      </Form.Item>
                    )}
                    <Form.Item className="hp-mt-16 hp-mb-20">
                      <Button type="primary" htmlType={balance === 0 ? "button" : "submit"} disabled={fileList.length === 0 && linkedInProfileLink.length === 0} onClick={handlePaymentClick}>
                        Recognize now
                      </Button>
                    </Form.Item>
                  </Form>
                  <p className="hp-photo-caption hp-text-center">The process may take up to 50 second to complete.</p>
                  {isError && linkedIn && errorType === 'linkedin' && <ErrorInfo
                      message={errorMessage}
                      className="hp-mb-lg-24 hp-mb-32"
                  />}
                </Col>
              </Row>
            </Col>
            {!isTablet && <RecognitionPageInfo />}
          </Row>
          <Row justify="center">
            <Footer />
          </Row>
          {marketingPopup && <MarketingSubscription/>}
        </>
  );
}

export default NewRecognitionPage;
