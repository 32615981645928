export const APP_ENV = process.env.REACT_APP_ENV
export const SERVER = process.env.REACT_APP_SERVER;
export const DEVICE_NAME = process.env.REACT_APP_DEVICE_NAME;
export const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY;
export const MAIN_PAGE = 'https://hrmny.business/';
export const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;

export const FB_LOGIN_LINK = `https://${SERVER}/login/facebook`;
export const GOOGLE_LOGIN_LINK = `https://${SERVER}/login/google`;
export const AUTH_COOKIE = process.env.REACT_APP_AUTH_COOKIE
export const CURRENCY = {
    'usd': '$',
    'eur': '€'
}
export const DEVICE_MAX_WIDTH = {
    'mobile': 767,
    'tablet': 1199,
}
