import { Button, Modal } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { clearMarketingPopup } from "../../../services/auth";
import { subscribe, unsubscribe } from "../../../services/subscription";

const MarketingSubscription = () => {
    const [subscriptionModal, setSubscriptionModal] = useState(true)
    const dispatch = useDispatch();
    const handleSubscriptionModalCancel = () => {
        dispatch(unsubscribe())
        setSubscriptionModal(false);
        dispatch(clearMarketingPopup())
    };
    const subscribeFunc = () => {
        dispatch(subscribe())
        dispatch(clearMarketingPopup())
        setSubscriptionModal(false)
    }
    return (
        <Modal
            footer={null} 
            open={subscriptionModal} 
            onCancel={handleSubscriptionModalCancel}
            title=""
            wrapClassName="hp-recognition-payment-modal"
            closable={false}
        >
            <h5 className="hp-p3-body hp-text-center">Newsletter signup</h5>
            <p className="hp-text-center hp-p1-body hp-mt-14 hp-mb-18">For us it is very important to have your direct permission to receive news, educational materials, and promotions from HRMNY SALES.</p>
            <Button block type="primary" className=" hp-mt-18 hp-p1-body hp-font-weight-600" onClick={subscribeFunc}>
                Yes, I want to receive
            </Button>
            <Button onClick={handleSubscriptionModalCancel } block className="hp-mb-24 hp-mt-18 hp-p1-body hp-font-weight-600 hp-text-color-primary-2 hp-border-color-primary-2">
                No, I do not want to receive
            </Button>
        </Modal>
       
    )
}

export default MarketingSubscription;
