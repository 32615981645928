import { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import Analytics from 'analytics';
import crazyEggPlugin from '@analytics/crazy-egg'
import { DEVICE_MAX_WIDTH } from "./constants";

const useAnalyticsEventTracker = (category="Blog category") => {
  const eventTracker = (action = "test action", label = "test label") => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}
export default useAnalyticsEventTracker;

export const findPath = (ob:Object, key:string) => {
    const path: string[] = [];
    const keyExists = (obj: Object) => {
      if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
        return false;
      }
      else if (obj.hasOwnProperty(key)) {
        return true;
      }
      else if (Array.isArray(obj)) {
        let parentKey = path.length ? path.pop() : "";

        for (let i = 0; i < obj.length; i++) {
          path.push(`${parentKey}[${i}]`);
          const result = keyExists(obj[i], key);
          if (result) {
            return result;
          }
          path.pop();
        }
      }
      else {
        for (const k in obj) {
          path.push(k);
          const result = keyExists(obj[k], key);
          if (result) {
            return result;
          }
          path.pop();
        }
      }
      return false;
    };

    keyExists(ob);

    return ob[path.join(".")][key];
  }


export const analytics = Analytics({
  app: 'hrmny.business',
  plugins: [
    crazyEggPlugin({
      accountNumber: '01155092'
    }),
  ]
})

export const useDeviceType = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < DEVICE_MAX_WIDTH.mobile);
      setIsTablet(window.innerWidth < DEVICE_MAX_WIDTH.tablet);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return [isMobile, isTablet];
}
