import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, userSelector } from '../../../services/auth';
import { Dropdown, Col, Row } from "antd";
import UserIconItem from "../../../view/main/widgets/user-icons/userIconItem";

export default function HeaderUser() {
  const dispatch = useDispatch();
  const { avatarUrl } = useSelector(
    userSelector
  );
  const logout = () => {
    dispatch(logoutUser())
  }
  const menu = (
    <div className="hp-user-dropdown hp-border-radius hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-p-12">

      <Row>
        <Col span={24}>
          <Link 
            to='/pages/contact-us'
            className="hp-d-flex-center hp-text-color-black-100 hp-p3-body hp-py-12 hp-px-8 hp-cursor-pointer hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius">
            Contact Us
          </Link>
        </Col>

        <Col span={24}>
          <span
            onClick={logout}
            className="hp-p3-body hp-hover-bg-dark-80 hp-py-12 hp-px-8 hp-cursor-pointer hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius"
          >
            Log out
          </span>
        </Col>
      </Row>
    </div>
  );

  return (
      <Dropdown overlay={menu} placement="bottomLeft">
        <div className="hp-border-radius-xl hp-cursor-pointer hp-border-1 hp-border-color-dark-80">
          <div className="hp-border-radius-lg hp-overflow-hidden hp-m-4 hp-d-flex" style={{ minWidth: 40, width: 40, height: 40 }}>
            <UserIconItem
              image={avatarUrl}
              imageHeight={40}
            />
          </div>
        </div>
      </Dropdown> 
  );
};
