import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, Input, Button, Spin } from 'antd';
import { sendContact, clearState, contactSelector } from "../../../services/contact";
import useAnalyticsEventTracker from '../../../utils';
import { errorState, getPersonas, getPrices, personaSelector } from '../../../services/persona';
import { fetchUserBytoken, userSelector } from '../../../services/auth';
import { LoadingOutlined } from '@ant-design/icons';
import ReactGA from 'react-ga4';
import Pusher from 'pusher-js';
import { PUSHER_APP_KEY, SERVER } from '../../../constants';
import HeaderText from '../../../layout/components/new-header/HeaderText';
import { Footer } from '../../../layout/components/new-footer';
import { ErrorInfo } from '../recognition/error';
import Confirmation from '../authentication/confirmation';

const { TextArea } = Input;

const ContactUs = () => {
  const [confirmation, showConfirmation] = useState(false);
  const gaEventTrackerRecognition = useAnalyticsEventTracker('Recognition');
  const history = useHistory();
  const { isFetching, isPersonaSent } = useSelector(
    personaSelector
  );
  const { id } = useSelector(userSelector)
  const antIcon = <LoadingOutlined style={{ fontSize: 96 }} spin />;
  const dispatch = useDispatch();

  const { isSuccess, isError, errorMessage } = useSelector(
    contactSelector
  );

  const onFinish = (data) => {
    dispatch(sendContact(data))
  };

  const handleBackClick = () => history.replace('/pages/recognition');

  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      showConfirmation(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    dispatch(clearState())
    dispatch(fetchUserBytoken())
    dispatch(getPersonas())
    dispatch(getPrices())
  },[]);

  useEffect(() => {
    if(isPersonaSent) {
      const pusher = new Pusher(PUSHER_APP_KEY, {
        cluster: 'eu',
        channelAuthorization: {
          endpoint: `https://${SERVER}/broadcasting/auth`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'application/json',
          }
        }
      });
      const channel = pusher.subscribe(`private-App.Models.User.${id}`);

      channel.bind('typingPersona', (dataChanel) => {
        if(dataChanel.typingPersona.process_state === 'done'){
          gaEventTrackerRecognition('wait_until_the_end_of_the_timer')
          history.push(`/pages/recognition/${dataChanel.typingPersona.id}`)
        } else {
          dispatch(errorState())
        }
      });
    }
  },[isPersonaSent])

  return isFetching ? (
    <div className="hp-d-flex-full-center hp-d-flex-column hp-mt-120">
      <Spin indicator={antIcon} />
      <p>Please wait. Processing data...</p>
    </div>
  ) : (
    <>
      <Row className="hp-recognition-page" justify="center">
        <Col xs={24}>
          <HeaderText user />
        </Col>
      </Row>
      <Row className="hp-recognition-form hp-contact-us hp-pl-24 hp-pr-24">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="hp-main-form"
        >
          <div className="hp-d-flex hp-align-items-center hp-mt-32 hp-mb-48 hp-mb-sm-42">
            <div className="hp-arrow-back hp-d-flex" onClick={handleBackClick}>
              <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.10724 16L0.470881 8.36364L8.10724 0.727273L9.41974 2.02273L4.01634 7.42614H18.0107V9.30114H4.01634L9.41974 14.6875L8.10724 16Z" fill="#2D3436"/>
              </svg>
            </div>
            <h3 className="hp-ml-16 hp-mb-0">Contact us</h3>
          </div>
          <Row justify="center">
            <Col
              sm={24}
              xs={24}
              md={10}
              lg={6}
            >
              {confirmation ? (
                <Confirmation />
              ) : (
                <Form
                  layout="vertical"
                  name="contactUs"
                  className="hp-mt-sm-16 hp-mt-32"
                  onFinish={onFinish}
                >
                  <Form.Item
                    label="Name"
                    className="hp-mb-16"
                    name="name"
                    validateStatus={isError ? "error" : ""}
                    rules={[
                      {
                        min: 1,
                      },
                    ]}>
                    <Input id="error" placeholder="Your name" />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    className="hp-mb-16"
                    name="email"
                    validateStatus={isError ? "error" : ""}
                    rules={[
                      {
                        type: 'email',
                      },
                    ]}>
                    <Input id="error" placeholder="Email address" />
                  </Form.Item>
                  <Form.Item
                    label="Message"
                    name="text"
                    rules={[
                      {
                        min: 1,
                      },
                    ]}>
                    <TextArea placeholder="Your message" rows={4} className="hp-mb-16"/>
                  </Form.Item>
                  {isError && <ErrorInfo
                    message={errorMessage}
                    className="hp-mb-lg-24 hp-mb-32"
                  />}
                  <Form.Item className="hp-mt-16 hp-mb-8">
                    <Button block type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center">
        <Footer />
      </Row>
    </>
  );
};

export default ContactUs;
