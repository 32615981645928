import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import { SERVER } from '../constants';
import axios from 'axios';

interface Contact {
    name: string,
    email: string,
    text: string,
  }

export const sendContact = createAsyncThunk<Contact>(
    'sendContact',
    async ( contact, thunkAPI) => {
      try {
        const response = await axios(
          `https://${SERVER}/public-feedback`,
          {
            method: 'POST',
            data: contact
          }
        );
        let data = await response.data;

          return data;
      } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const contactSlice = createSlice({
    name: 'contact',
    initialState: {
      isFetching: false,
      isSuccess: false,
      isError: false,
      errorMessage: '',
    },
    reducers: {
      clearState: (state) => {
        state.isError = false;
        state.isSuccess = false;
        state.isFetching = false;
        return state;
      }
    },
    extraReducers: (builder) => {
      builder.addCase(sendContact.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(sendContact.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(sendContact.rejected, (state, {payload}) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.message;
      })
  },
  });

  export const { clearState } = contactSlice.actions;

  export const contactSelector = (state: RootState) => state.contact;
