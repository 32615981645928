import { Col, List } from 'antd';
import React from 'react';

const dataList = [
  'make a first contact',
  'schedule a meeting',
  'identify prospect needs',
  'make an offer',
  'make a first contact',
  'schedule a meeting',
  'identify prospect needs',
  'make an offer',
];

const Insights = () => (
  <Col
    lg={6}
    className="hp-form-info"
  >
    <div className="hp-insights-block">
      <h5 className="hp-mb-18 hp-p1-body hp-font-weight-600">Get insights on how to:</h5>
      <List
        itemLayout="horizontal"
        dataSource={dataList}
        split={false}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M12 1.11816L3.65373 10L0 6.11182L1.05074 4.99365L3.65373 7.76367L10.9493 0L12 1.11816Z" fill="#2D3436"/>
                </svg>
              }
              description={item}
            />
          </List.Item>
        )}
      />
    </div>
  </Col>
);

export default Insights;
