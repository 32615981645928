import useAnalyticsEventTracker, { useDeviceType } from '../../../utils';
import React, { useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import {
  clearState,
  errorState,
  getPersonas,
  getPrices,
  personaSelector,
} from '../../../services/persona';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserBytoken, userSelector } from '../../../services/auth';
import ReactGA from 'react-ga4';
import Pusher from 'pusher-js';
import { PUSHER_APP_KEY, SERVER } from '../../../constants';
import { Button, Col, Row } from 'antd';
import HeaderText from '../../../layout/components/new-header/HeaderText';
import MarketingSubscription from '../../components/subscription';
import { Footer } from '../../../layout/components/new-footer';
import Insights from './insights';
import Spinner from './spinner';
import PersonasList from './personas-list';

const RecognitionPage = () => {
  const gaEventTrackerRecognition = useAnalyticsEventTracker('Recognition');
  const [isMobile, isTablet] = useDeviceType();
  const history = useHistory();
  const { isFetching, personas, isPersonaSent } = useSelector(
    personaSelector
  );
  const { id, marketingPopup, balance } = useSelector(userSelector)
  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    dispatch(clearState())
    dispatch(fetchUserBytoken())
    dispatch(getPersonas())
    dispatch(getPrices())
  },[]);

  useEffect(() => {
    if(isPersonaSent) {
      const pusher = new Pusher(PUSHER_APP_KEY, {
        cluster: 'eu',
        channelAuthorization: {
          endpoint: `https://${SERVER}/broadcasting/auth`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'application/json',
          }
        }
      });

      const channel = pusher.subscribe(`private-App.Models.User.${id}`);

      channel.bind('typingPersona', (dataChanel) => {
        if(dataChanel.typingPersona.process_state === 'done'){
          gaEventTrackerRecognition('wait_until_the_end_of_the_timer')
          history.push(`/pages/recognition/${dataChanel.typingPersona.id}`)
        } else {
          dispatch(errorState())
        }
      });
    }
  },[isPersonaSent])

  return isFetching ? (
    <Spinner />
  ) : (
    <>
      <Row className="hp-recognition-page" justify="center">
        <Col xs={24}>
          <HeaderText user />
        </Col>
      </Row>
      <Row
        justify="space-between"
        className="hp-recognition-form hp-pl-24 hp-pr-24"
        gutter={{ xs: 0, sm: 0, md: 37 }}
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={18}
          className="hp-main-form hp-any-contacts-recognition"
        >
          <div className="hp-d-flex hp-align-items-center hp-mt-32 hp-mb-32 hp-mb-sm-18">
            <h3 className="hp-mb-0">My recognitions</h3>
          </div>
          {!!personas?.length ? (
            <PersonasList personas={personas} />
          ) : (
            <Row justify="center">
              <Col
                sm={24}
                xs={24}
                md={10}
                lg={8}
              >
                <div className="hp-any-contacts-block">
                  <p className="hp-mb-12 hp-lg-mb-18">You don’t have any contacts yet.</p>
                  <Link to="/pages/new-recognition">
                    <Button
                      type="primary"
                      htmlType="button"
                      disabled={balance === 0}
                    >
                      + New recognition
                    </Button>
                  </Link>
                </div>
                {isTablet && <Insights />}
              </Col>
            </Row>
          )}
        </Col>
        {!isTablet && <Insights />}
      </Row>
      <Row justify="center">
        <Footer />
      </Row>
      {marketingPopup && <MarketingSubscription />}
    </>
  );
}

export default RecognitionPage;

