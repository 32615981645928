import {  Row } from "antd";
import logo from "../../../assets/images/logo/Logotype_main_header.svg";
import HeaderUser from "./HeaderUser";
import { Link } from "react-router-dom";

export default function HeaderText({ user }) {
  return (
    <Row
      className={`"hp-header-left-text hp-d-flex-center hp-d-flex-between ${user ? "hp-pl-24 hp-pr-24" : ""}`}
      style={{ marginTop: 24 }}
    >
      <Link to="/pages/recognition">
        <div className=" hp-overflow-hidden  hp-d-flex" style={{ minWidth: 32, width: 32, height: 32 }}>
          <img src={logo} alt="logo" style={{ marginTop: 'auto', marginLeft: 'auto' }} />
        </div>
      </Link>
      {user && <HeaderUser/>}
    </Row>
  );
};
