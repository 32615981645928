import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import { SERVER } from '../constants';
import axios from 'axios';
import useAnalyticsEventTracker from '../utils';
interface MyData {
  [index: number]: {
    avatar?: string,
    name?: string,
    process_state?: string
  }
}
interface Checkout {
  currencyValue: string,
  quantity: number,
  checkout_url: string
}

interface Prices {
  unit_amount: number,
  currency: string
}
interface Auth {
  postData: FormData
}
export const getPersonas = createAsyncThunk<MyData>(
  'getPersonas',
  async () => {
    try {
      const response = await axios(
        `https://${SERVER}/api/personas`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        }
      );
      let data = await response.data;

      if (response.status === 200) {
        return data;
      }
    } catch (e) {
      // TODO: log errors
    }
  }
);

export const getPrices = createAsyncThunk<Prices>(
  'getPrices',
  async () => {
    try {
      const response = await axios(
        `https://${SERVER}/api/payment/price`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        }
      );
      let data = await response.data;

      if (response.status === 200) {
        return data;
      }
    } catch (e) {
      // TODO: log errors
    }
  }
);

export const checkout = createAsyncThunk<Checkout>(
  'checkout',
  async ({quantity, currencyValue}, thunkAPI) => {
    try {
      const response = await axios(
        `https://${SERVER}/api/payment/checkout?quantity=${quantity}&currency=${currencyValue}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      let data = await response.data;

      if (response.status === 200) {
        window.location.href = data.checkout_url
      }
      else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const sendPersona = createAsyncThunk<MyData,Auth>(
  'sendPersona',
  async ( postData, thunkAPI) => {
    try {

      const response = await axios(
        `https://${SERVER}/api/traits/process`,
        {
          method: 'POST',
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          data: postData,
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        const gaEventTrackerRecognition = useAnalyticsEventTracker('Recognition');
        gaEventTrackerRecognition('timer');
        return data
      }

    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const sendLinkedinPersona = createAsyncThunk<MyData,Auth>(
    'sendLinkedinPersona',
    async ( postData, thunkAPI) => {
        try {

            const response = await axios(
                `https://${SERVER}/api/typing_by_linkedin`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                    data: postData,
                }
            );
            let data = await response.data;
            if (response.status === 200) {
                const gaEventTrackerRecognition = useAnalyticsEventTracker('Recognition');
                gaEventTrackerRecognition('timer');
                return data
            }

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const personaSlice = createSlice({
    name: 'persona',
    initialState: {
      checkout_url:'',
      personaName: '',
      personas: null,
      feedback: null,
      persona: {},
      prices: {},
      isFetching: false,
      isSuccess: false,
      isTimer: false,
      timerDelay: 10,
      isError: false,
      errorMessage: '',
      errorType: '',
      isPersonaSent: false,
    },
    reducers: {
      clearState: (state) => {
        state.isError = false;
        state.isSuccess = false;
        state.isFetching = false;
        state.isTimer = false;
        return state;
      },
      errorState: (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.isFetching = false;
        state.isTimer = false;
        state.errorType =  action.payload?.type || 'photo';
        state.errorMessage = action.payload?.message || 'No people were identified. Please upload a clear facial photo, or use direct photo upload';
        return state;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(sendPersona.fulfilled, (state, { payload }) => {
        state.isFetching = true;
        state.isSuccess = true;
        state.isPersonaSent = payload;
      })
      .addCase(sendPersona.pending, (state) => {
        state.isFetching = true;
        state.isTimer = true;
      })
      .addCase(sendPersona.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorType = 'photo';
        state.errorMessage = payload.message;
      })
      .addCase(sendLinkedinPersona.fulfilled, (state, { payload }) => {
          state.isFetching = true;
          state.isSuccess = true;
          state.isPersonaSent = payload;
      })
      .addCase(sendLinkedinPersona.pending, (state) => {
          state.isFetching = true;
          state.isTimer = true;
          state.timerDelay = 60;
      })
      .addCase(sendLinkedinPersona.rejected, (state, { payload }) => {
          state.isFetching = false;
          state.isError = true;
          state.errorType = 'linkedin';
          state.errorMessage = payload.message;
      })
      .addCase(getPersonas.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.personas = payload;
      })
      .addCase(getPersonas.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getPersonas.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getPrices.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.prices = payload;
      })
      .addCase(getPrices.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getPrices.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
      })

  },
  });

  export const { clearState, errorState } = personaSlice.actions;

  export const personaSelector = (state: RootState) => state.persona;
