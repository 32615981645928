import React from 'react'
import { Col, Row } from 'antd'

export default function Footer() {
  return (
    <Col className="hp-mt-sm-48 hp-mt-96 hp-mb-32">

      <Row align="middle" justify="center" gutter={[16]}>
        <Col>
          <a href="https://hrmny.business/privacy-policy" className="hp-text-color-black-80 hp-text-color-dark-40">
            Privacy Policy
          </a>
        </Col>

        <Col>
          <a href="https://hrmny.business/terms-and-conditions" className="hp-text-color-black-80 hp-text-color-dark-40">
            Term of use
          </a>
        </Col>
      </Row>
    </Col>
  )
}
