import React from 'react';

type Props = {
	message: string;
	className: string;
}

export const ErrorInfo: React.FC<Props> = ({ message, className }) => (
	<div className={`${className} hp-error-info`}>
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M12.866 2.99996L22.392 19.5C22.4798 19.652 22.526 19.8244 22.526 20C22.526 20.1755 22.4798 20.3479 22.392 20.4999C22.3042 20.652 22.178 20.7782 22.026 20.866C21.874 20.9537 21.7015 21 21.526 21H2.474C2.29846 21 2.12602 20.9537 1.97401 20.866C1.82199 20.7782 1.69576 20.652 1.60799 20.4999C1.52022 20.3479 1.47402 20.1755 1.47402 20C1.47402 19.8244 1.52023 19.652 1.608 19.5L11.134 2.99996C11.2218 2.84795 11.348 2.72172 11.5 2.63396C11.652 2.5462 11.8245 2.5 12 2.5C12.1755 2.5 12.348 2.5462 12.5 2.63396C12.652 2.72172 12.7782 2.84795 12.866 2.99996V2.99996ZM4.206 19H19.794L12 5.49996L4.206 19V19ZM11 16H13V18H11V16ZM11 8.99996H13V14H11V8.99996Z" fill="#C7443B"/>
		</svg>
		{message}
	</div>
);
