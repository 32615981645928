import React from 'react';
import {Avatar, Menu, MenuProps} from "antd";
import {IProps, MenuItem} from "./types";
import {useSelector} from "react-redux";
import {personaItemSelector} from "../../../../../services/personaItem";
import {Chat, Calendar, Doubts, List, Offer, Pitch, Pricing, Rocket} from "../../../../../assets/images";
import {useHistory} from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import {useDeviceType} from "../../../../../utils";
import { MENU_LABELS, MenuKeys } from '../constants';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';

const MenuComponent = (props: IProps) => {
  const history = useHistory();
  const [isMobile] = useDeviceType();

  const handleClick = () => {
    history.push('/pages/recognition');
  }

  const { persona }: any = useSelector(personaItemSelector);

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const menuItems: MenuProps['items'] = [
    getItem('CONTACT', 'grp1', null, [
      getItem(MENU_LABELS[MenuKeys.FirstItem], MenuKeys.FirstItem, <Chat className='iconMenu' />),
      { type: 'divider' },
      getItem(MENU_LABELS[MenuKeys.SecondItem], MenuKeys.SecondItem, <Calendar className='iconMenu' />)
    ], 'group'),

    { type: 'divider' },

    getItem('OFFER', 'grp2', null, [
      getItem(MENU_LABELS[MenuKeys.ThirdItem], MenuKeys.ThirdItem, <List className='iconMenu' />),
      { type: 'divider' },
      getItem(MENU_LABELS[MenuKeys.FourthItem], MenuKeys.FourthItem, <Offer className='iconMenu' />)
    ], 'group'),

    { type: 'divider' },

    getItem('NEGOTIATE', 'grp3', null, [
      getItem(MENU_LABELS[MenuKeys.FifthItem], MenuKeys.FifthItem, <Pitch className='iconMenu' />),
      { type: 'divider' },
      getItem(MENU_LABELS[MenuKeys.SixthItem], MenuKeys.SixthItem, <Pricing className='iconMenu' />)
    ], 'group'),

    { type: 'divider' },

    getItem('CLOSE', 'grp4', null, [
      getItem(MENU_LABELS[MenuKeys.SeventhItem], MenuKeys.SeventhItem, <Doubts className='iconMenu' />),
      { type: 'divider' },
      getItem(MENU_LABELS[MenuKeys.EighthItem], MenuKeys.EighthItem, <Rocket className='iconMenu' />)
    ], 'group'),
  ];

  const handleMenuClick: MenuClickEventHandler = (e) => {
    props.onItemClick(e.key as MenuKeys);

    if (isMobile && props.changeContent) {
      props.changeContent(false);
    }
  }

  return (
    <div>
      <div className='userBlock'>
        <div>
          <div className='arrowBlock'>
            <ArrowLeftOutlined onClick={handleClick} className='iconMenu'/>
          </div>
          <Avatar size={86} src={persona && persona.avatar} className='avatarBlock'/>
          <h5 className="hp-mt-10 hp-font-weight-600 hp-text-center hp-word-break title-width">
            {persona.name}
          </h5>
        </div>
      </div>
      <Menu
        onClick={handleMenuClick}
        className='menuBlock'
        mode="inline"
        items={menuItems}
      />
    </div>
  )
}

export default MenuComponent;
