import React from "react";
import { useLocation, Link } from "react-router-dom";
import { Row, Col } from "antd";
import Success from '../../../../assets/images/components/success_icon.svg';
import Declined from '../../../../assets/images/components/declined_icon.svg'

const PaymentStatus = () => {
    const location = useLocation();
    
    return (
        <Row justify='center'>
            <Col className="hp-d-flex-full-center hp-d-flex-column hp-mt-160">
                <img src={location.pathname.includes('success') ? Success : Declined} alt='Status' />
                <h3 className="hp-mt-18 hp-mb-48">{location.pathname.includes('success') ? 'Thank you for your purchase' : 'Payment declined'}</h3>
                <Link to='/pages/recognition' className='ant-btn ant-btn-primary'>Go to main page</Link>
            </Col>
            
        </Row>
    )
}

export default PaymentStatus;