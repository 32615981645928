import { lazy } from "react";

const PrivatePagesRoutes = [
    {
        path: "/pages/new-recognition",
        component: lazy(() => import("../../view/pages/recognition/new-recognition")),
        layout: "FullLayout",
      },
];

export default PrivatePagesRoutes;
