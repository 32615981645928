import {Button, Checkbox, Col, Form, Input, Modal, Rate, Row, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import Success from "../../../../../assets/images/components/success_icon.svg";
import expectationLogo from "../../../../../assets/images/svg/expectation.svg";
import actionLogo from "../../../../../assets/images/svg/action.svg";
import exampleLogo from "../../../../../assets/images/svg/example.svg";
import useAnalyticsEventTracker from "../../../../../utils";
import { sendFeedback } from "../../../../../services/personaItem";
import ReactGA from "react-ga4";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {IProps} from "./types";
import { MENU_LABELS } from '../constants';

const optionsOneTwoStars = [
  { label: 'Recommendations were inaccurate or useless', value: 1 },
  { label: 'Recommendations didn’t produce the expected result', value: 2 },
  { label: 'Recommendations only made it worse', value: 3 },
  { label: 'Recommendations didn’t include enough information', value: 4 },
];
const optionsThreeFourStars = [
  { label: 'Recommendations were quite good, but I need more information', value: 1 },
  { label: 'Recommendations were quite good, but didn’t produce the expected result', value: 2 },
  { label: 'Recommendations helped me to move forward only at the initial stage', value: 3 },
  { label: 'There was not enough information. I need some more tips and tricks', value: 4 },
]
const { TextArea } = Input;

const UserContent = ({ content, feedback, activeTab, setActiveTab, menuItem }: IProps) => {
  const gaEventTrackerFeedback = useAnalyticsEventTracker('Feedback-form');
  const { search } = useLocation();
  const popup = new URLSearchParams(search).get('popup');
  const [form] = Form.useForm();
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedbackValue, setFeedbackValue] = useState(0);
  const dispatch = useDispatch();

  const isPersonaHasContent = !!Object.keys(content).length;

  const showFeedbackModal = (value: any) => {
    gaEventTrackerFeedback('feedback');
    gaEventTrackerFeedback(`feedback_${value}`);
    setFeedbackValue(value);
    setFeedbackModal(true);
  };

  const onFinish = (data: any) => {
    dispatch(sendFeedback(data));
  }

  const handleFeedbackModalCancel = () => {
    setFeedbackModal(false);
  };

  const handleChange = (val: any) => {
    setFeedbackValue(val);
    form.setFieldsValue({ details: [] });
  };

  useEffect( () => {
    popup && gaEventTrackerFeedback('feedbackPopup')
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    setFeedbackModal(popup);
  },[]);

  const getTabName = (str: string) => str.replace(/_/g, " ").charAt(0).toUpperCase() + str.replace(/_/g, " ").slice(1);

  const getCommunicationContent = (content: any) => {
    if (!content) {
      return;
    }

    return (
      <Row gutter={40} style={{
        paddingRight: 20
      }}>
        <Col md={8}>
          <div style={{ display: "flex" }}>
            <img className="hp-mr-8" src={expectationLogo} alt="expectationLogo" />
            <div>Client expectation</div>
          </div>
          <p className='hp-pl-32'>{content.goal_state}</p>
        </Col>
        <Col md={8}>
          <div style={{ display: "flex" }}>
            <img className="hp-mr-8" src={actionLogo} alt="actionLogo" />
            <div>Your actions</div>
          </div>
          <p className='hp-pl-32'>{content.do}</p>
        </Col>
        <Col md={8}>
          <div style={{ display: "flex" }}>
            <img className="hp-mr-8" src={exampleLogo} alt="exampleLogo" />
            <div>Example</div>
          </div>
          <p className='hp-pl-32'>{content.do_example}</p>
        </Col>
      </Row>
    )
  }

  const getFeedbackContent = () => {
    return (
      <>
        <Row justify="center">
          <Col className="hp-d-flex-full-center hp-d-flex-column hp-mb-80">
            {
              feedback
                ? (
                  <>
                    <h5 className="hp-mt-24 hp-mb-18 hp-font-weight-600">Thank you for your feedback</h5>
                    <Rate disabled defaultValue={feedback.rate} value={feedback.rate} />
                  </>
                )
                : (
                  <>
                    <h5 className="hp-mt-24 hp-mb-18 hp-font-weight-600">Please rate your experience</h5>
                    <Rate onChange={showFeedbackModal}/>
                  </>
                )
            }
          </Col>
        </Row>
        <Modal
          footer={null}
          open={feedbackModal}
          onCancel={handleFeedbackModalCancel}
          title=""
          wrapClassName="hp-recognition-payment-modal"
        >
          { feedback ?  (
            <div className="hp-d-flex-full-center hp-d-flex-column">
              <img src={Success} alt="mark" style={{ width:'61px', height:'61px'}}/>
              <h4 className="hp-font-weight-700 hp-text-center hp-mt-18 hp-mb-12">Thank you for your feedback!</h4>
              <p className="hp-text-center">We are working hard on improvements and your feedback is very important.</p>
            </div>
          ) : (
            <Form
              layout="vertical"
              name="feedback"
              onFinish={onFinish}
              form={form}
            >
              <h3 className="hp-mb-18 feedback-popup">Please rate your experience</h3>
              <Form.Item label="" name="rate" initialValue={feedbackValue} rules={[{ required: true, min:1, type: 'number', message: 'Please rate your experience' }]}>
                <Rate onChange={handleChange}/>
              </Form.Item>

              {feedbackValue < 3
                ? <><p>We're sorry you're not happy with the result. Please tell us what you didn’t like</p><Form.Item name="details" label=""><Checkbox.Group options={optionsOneTwoStars} value={[]} /></Form.Item></>
                : feedbackValue < 5 && feedbackValue >2 && <><p>We’re glad that you found our recommendations useful. What stopped you from putting 5 stars?</p><Form.Item name="details" label=""><Checkbox.Group value={[]} options={optionsThreeFourStars}  /></Form.Item></>
              }

              <p className="hp-mb-16 hp-mt-24">Your opinion</p>
              <Form.Item label="" name="own_version">
                <TextArea rows={4} className="hp-mb-16" onClick={()=>gaEventTrackerFeedback('feedback_own_version_insert')}/>
              </Form.Item>
              <Form.Item >
                <Button htmlType="submit" disabled={feedbackValue === 0 } className="mb-0" type="primary" onClick={()=>gaEventTrackerFeedback('feedback_sent')}>Submit</Button>
              </Form.Item>
            </Form>
          )}
        </Modal>
      </>
    )
  }

  const getTabsItem = (content: any) => {
    const tabsArr = Object.keys(content);

    return tabsArr.map((cur, index) => {
      return (
        {
          key: index.toString(),
          label: getTabName(tabsArr[index]),
          children: getCommunicationContent(content[cur])
        })
    })
  };

  return isPersonaHasContent &&  (
    <>
      <div>
        <h1 className='contentTitle'>
          {MENU_LABELS[menuItem]}
        </h1>
        <h3 className='contentSubTitle'>
          The first impression is essential for building relationships with the client.
        </h3>
        <Tabs
          className="hp-mt-20 tabBlock"
          activeKey={activeTab}
          items={getTabsItem(content)}
          onChange={setActiveTab}
        />
      </div>
      {getFeedbackContent()}
    </>
  );
};

export default UserContent;
