import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import { SERVER } from '../constants';
import axios from 'axios';

export const subscribe = createAsyncThunk(
    'subscribe',
    async () => {
      try {
        const response = await axios(
          `https://${SERVER}/api/subscribe`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          }
        );
        let data = await response.data;

          return data;
      } catch (e) {
        // TODO: log errors
      }
    }
  );

  export const unsubscribe = createAsyncThunk(
    'unsubscribe',
    async () => {
      try {
        const response = await axios(
          `https://${SERVER}/api/unsubscribe`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          }
        );
        let data = await response.data;

          return data;
      } catch (e) {
        // TODO: log errors
      }
    }
  );

  export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: {
      isFetching: false,
      isSuccess: false,
      isError: false,
      errorMessage: '',
    },
    reducers: {
      clearState: (state) => {
        state.isError = false;
        state.isSuccess = false;
        state.isFetching = false;
        return state;
      }
    },
    extraReducers: (builder) => {
      builder.addCase(subscribe.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(subscribe.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(subscribe.rejected, (state, {payload}) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.message;
      })
      .addCase(unsubscribe.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(unsubscribe.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(unsubscribe.rejected, (state, {payload}) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.message;
      })
  },
  });

  export const { clearState } = subscriptionSlice.actions;

  export const subscriptionSelector = (state: RootState) => state.subscription;
