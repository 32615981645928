import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import {Row, Col, Form, Input, Button, Checkbox, Alert} from "antd";
import { loginUser, userSelector, clearState } from '../../../../services/auth';
import SocialProfile from "../../profile/connect-with-social";
import HeaderText from "../../../../layout/components/header/HeaderText";
import Footer from "../footer";
import useAnalyticsEventTracker from "../../../../utils";
import {analytics} from "../../../../utils";
import { Fatrows } from "iconsax-react";

export default function Login() {
  const gaEventTracker = useAnalyticsEventTracker('login');
  const history = useHistory();
  const { search } = useLocation();
  const { isSuccess, isError, errorsLogin, errorMessage } = useSelector(
    userSelector
  );
  const passwordReset = new URLSearchParams(search).get('passwordReset')
  const dispatch = useDispatch();
  const onFinish = (data) => {
    dispatch(loginUser(data));
  };
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    dispatch(clearState());

  }, []);

  useEffect(() => {
    if (isError) {
      errorsLogin && errorsLogin.verified && history.replace('/pages/authentication/email-confirmation')
      /*Object.keys(errorsLogin).forEach( (field) => setError(field, {
        type: "server",
        message: errorsLogin[field].join(' | ')})
      )
      dispatch(clearState());*/
    }

    if (isSuccess) {
      dispatch(clearState());
      history.replace('/pages/recognition')
    }
  }, [isError, isSuccess ]);
  return (
    <Row gutter={[32, 0]} className="hp-authentication-page">


      <Col span={24} className="hp-py-sm-0" justify="center">
      <HeaderText />
        <Row className="hp-h-100" align="middle" justify="center">

          <Col
            xxl={7}
            xl={7}
            lg={9}
            md={14}
            sm={24}
            className="hp-pt-24 hp-pb-48"
          >
            {passwordReset && <Alert message="Your password has been changed!" type="success"/>}
            <h2>Login</h2>

            <Col className="hp-text-center">
              <SocialProfile/>
            </Col>

            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item label="Email" className="hp-mb-16" name="email" validateStatus={isError ? "error" : ""}>
                <Input id="error" autoComplete="off" />
              </Form.Item>

              <Form.Item label="Password" className="hp-mb-8" name="password" validateStatus={isError ? "error" : ""}>
                <Input.Password id="warning2" autoComplete="new-password"/>
              </Form.Item>

              {isError && <Alert
                  message={errorMessage}
                  type="error"
              />}

              <Row align="middle" justify="space-between">
                <Form.Item className="hp-mb-0">
                  <Checkbox name="remember">Remember me</Checkbox>
                </Form.Item>

                <Link
                  onClick={() => gaEventTracker('password_conf')}
                  className="hp-button hp-text-color-black-80 hp-text-color-dark-40"
                  to="/pages/authentication/recover-password"
                >
                  Forgot Password?
                </Link>
              </Row>

              <Form.Item className="hp-mt-16 hp-mb-8">

                  <Button block type="primary" htmlType="submit" onClick={() => gaEventTracker('authorization')}>
                    Sign in
                  </Button>

              </Form.Item>
            </Form>

            <Col className="hp-text-center">
              <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-font-weight-400 hp-mr-4">
                Don’t you have an account?
              </span>

              <Link
                onClick={() => gaEventTracker('new_acc')}
                className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
                to="/pages/authentication/register"
              >
                Create an account
              </Link>
            </Col>


            <Col>
              <Footer />
            </Col>
          </Col>

        </Row>
      </Col>
    </Row>
  );
};
