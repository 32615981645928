import { Button, Col, Row } from 'antd';
import { useLocation, Link } from "react-router-dom";
import Success from '../../../../assets/images/components/success_icon.svg';
import React from 'react';

const Confirmation = () => {
  const location = useLocation();

  return (
    <>
      <Row sm={24} span={24} justify='center' align="middle" className='hp-confirmation hp-pl-24 hp-pr-24'>
        <Col>
          <div className="hp-d-flex-full-center hp-d-flex-column hp-mt-48">
            <img src={Success} alt="mark" style={{ width:'61px', height:'61px'}} />
            {location.pathname.includes('email') && <h3>Confirm your email</h3>}
            <p className='hp-mt-18 hp-mb-0'>{location.pathname.includes('email') ? 'We’ll e-mail you instructions on how to reset your password.' : 'Your message has been sent to us'}</p>
            {location.pathname.includes('contact') && (
              <Link to='/pages/recognition' className='hp-mt-48'>
                <Button type="ghost" htmlType="button"  style={{backgroundColor: "white"}}>Return to main page</Button>
              </Link>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Confirmation;
