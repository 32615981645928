import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

type Props = {
  sec: number
}

const Loader: React.FC<Props> = ({ sec }) => {
  const [percent, setPercent] = useState(0);
  const history = useHistory();

  useEffect(() => {
    let timerId = setInterval(() => setPercent((state) => state + 1), sec * 10);
    return () => clearInterval(timerId);
  }, [])

  if (percent / 10 === sec) {
    history.push('/pages/recognition');
  }

  return (
    <Row justify="center" className="hp-recognition-loader">
      <Col
        sm={24}
        xs={24}
        md={8}
        lg={8}
      >
        <p>Recognition in process...</p>
        <div className="hp-recognition-loader-scale hp-mb-6 hp-mt-12">
          <div style={{ width: `${percent}%`, background: "#0063F7" }} className="hp-recognition-loader-scale" />
        </div>
        <p>{percent} %</p>
      </Col>
    </Row>
  );
};

export default Loader;
