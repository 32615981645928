import { combineReducers } from '@reduxjs/toolkit'
import { userSlice } from '../services/auth'
import { contactSlice } from '../services/contact';
import { personaSlice } from '../services/persona';
import { personaItemSlice } from '../services/personaItem';

import customiseReducer from "./customise/customiseReducer";
import { subscriptionSlice } from './../services/subscription';

const rootReducer = combineReducers({
    user: userSlice.reducer,
    persona: personaSlice.reducer,
    personaItem: personaItemSlice.reducer,
    contact: contactSlice.reducer,
    subscription: subscriptionSlice.reducer,
    customise: customiseReducer,
})

export default rootReducer