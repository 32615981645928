import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import { DEVICE_NAME, MAIN_PAGE, SERVER } from '../constants';
import avatarImg from "../assets/images/users/user-1.svg";

export interface MyData {
  name: string;
  email: string;
  password: string;
  message: string;
  balance: number;
  user: {
    name: string;
    email: string;
    password: string;
  }
}
export interface Auth {
  subscribe: boolean,
  token: string,
  email: string,
  password: string,
  message: string,
  password_confirmation: string,
}
export const signupUser = createAsyncThunk<MyData,Auth>(
  'users/signupUser',
  async ({ subscribe, email, password, password_confirmation }, thunkAPI) => {
    try {
      const response = await fetch(
        `https://${SERVER}/api/sanctum/signup`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            subscribe,
            email,
            password,
            password_confirmation
          }),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        localStorage.setItem('token', data.token);
        return { ...data, email: email };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const requestPasswordReset = createAsyncThunk<MyData,Auth>(
  'users/requestPasswordReset',
  async ({ email }, thunkAPI) => {
    try {
      const response = await fetch(
        `https://${SERVER}/api/request-password-reset`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email
          }),
        }
      );
      let data = await response.json();

      if (response.status === 200) {

        return { ...data, email: email };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const passwordReset = createAsyncThunk<MyData,Auth>(
  'users/passwordReset',
  async ({ password, password_confirmation, token, email }, thunkAPI) => {
    try {
      const response = await fetch(
        `https://${SERVER}/api/reset-password`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            token,
            password,
            password_confirmation
          }),
        }
      );
      let data = await response.json();

      if (response.status === 200) {

        return data
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const loginUser = createAsyncThunk<MyData, Auth>(
  'users/login',
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await fetch(
        `https://${SERVER}/api/sanctum/token`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            password,
            device_name:DEVICE_NAME
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        localStorage.setItem('token', data.token);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk(
  'users/logout',
  async () => {
    try {
      const response = await fetch(
        `https://${SERVER}/api/logout`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        }
      );
      let data = await response.json();
      localStorage.removeItem('token');
      window.location.href = MAIN_PAGE;
      return data;
    } catch (e) {
      // TODO: log errors
    }
  }
);

export const fetchUserBytoken = createAsyncThunk(
  'users/fetchUserByToken',
  async () => {
    try {
      const response = await fetch(
        `https://${SERVER}/api/user`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      }
    } catch (e) {
      // TODO: log errors
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    passwordStatus:'',
    isVerifiedEmail: false,
    username: '',
    email: '',
    balance: 0,
    id: 0,
    isFetching: false,
    message: '',
    isSuccess: false,
    isError: false,
    isSuccessLogout: false,
    isErrorLogout: false,
    errorsLogin: null,
    errorMessage: '',
    avatarUrl: '',
    marketingPopup: false
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isErrorLogout = false;
      state.isSuccessLogout = false;
      return state;
    },
    clearMarketingPopup: (state) => {
      state.marketingPopup = false;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(signupUser.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.email = payload.email;
      state.username = payload.name;
    })
    .addCase(signupUser.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(signupUser.rejected, (state, { payload}) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    })
    .addCase(loginUser.fulfilled, (state, { payload }) => {
      state.email = payload.email;
      state.username = payload.name;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    })
    .addCase(loginUser.rejected, (state, { payload }: any) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
      state.errorsLogin = payload.errors;
    })
    .addCase(loginUser.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(logoutUser.fulfilled, (state) => {
      state.isFetching = false;
      state.isSuccessLogout = true;
      return state;
    })
    .addCase(logoutUser.rejected, (state, { payload }: any) => {
      state.isFetching = false;
      state.isErrorLogout = true;
      state.errorMessage = payload.message;
    })
    .addCase(logoutUser.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(fetchUserBytoken.fulfilled, (state, {payload}) => {
      state.isFetching = false;
      state.isSuccessLogout = true;
      state.email = payload.email;
      state.isVerifiedEmail = payload.verified;
      state.username = payload.name;
      state.balance = payload.balance;
      state.id = payload.id
      state.avatarUrl = payload.avatar_url;

      if (payload.avatar_url === null)  {
        state.avatarUrl = avatarImg;
      }
      state.marketingPopup = payload.is_show_marketing_pop_up;
    })
    .addCase(fetchUserBytoken.rejected, (state, { payload }: any) => {
      state.isFetching = false;
      state.isErrorLogout = true;
      state.errorMessage = payload.message;
    })
    .addCase(fetchUserBytoken.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(requestPasswordReset.fulfilled, (state, {payload}) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.email = payload.email;
    })
    .addCase(requestPasswordReset.rejected, (state, { payload }: any) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    })
    .addCase(requestPasswordReset.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(passwordReset.fulfilled, (state, {payload}) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.passwordStatus = payload.status;
    })
    .addCase(passwordReset.rejected, (state, { payload }: any) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    })
    .addCase(passwordReset.pending, (state) => {
      state.isFetching = true;
    })
  },
});

export const { clearState, clearMarketingPopup } = userSlice.actions;

export const userSelector = (state: RootState) => state.user;
