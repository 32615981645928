import { Col, List, Row } from 'antd';
import GoodPhoto1 from '../../../../assets/images/pages/recognition/good-photo-1.jpg';
import GoodPhoto2 from '../../../../assets/images/pages/recognition/good-photo-2.jpg';
import GoodPhoto3 from '../../../../assets/images/pages/recognition/good-photo-3.jpg';
import BadPhoto1 from '../../../../assets/images/pages/recognition/bad-photo-1.jpg';
import BadPhoto2 from '../../../../assets/images/pages/recognition/bad-photo-2.jpg';
import BadPhoto3 from '../../../../assets/images/pages/recognition/bad-photo-3.jpg';
import React from 'react';
import { useDeviceType } from '../../../../utils';

const dataList = [
	'Upload one photo in JPG format',
	'Maximum file size is 20Mb',
	'Make sure the person face is fully visible',
	'Don’t upload dark or group photos',
];

type Props = {
	onClose?(): void
}

export const RecognitionPageInfo: React.FC<Props> = ({ onClose }) => {
	const [isMobile, isTablet] = useDeviceType();

	return (
		<Col
			lg={6}
			className="hp-form-info"
		>
			{isTablet && (
				<>
					<div onClick={onClose} className="hp-form-info-close-btn">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M12 10.5862L16.95 5.63623L18.364 7.05023L13.414 12.0002L18.364 16.9502L16.95 18.3642L12 13.4142L7.05 18.3642L5.636 16.9502L10.586 12.0002L5.636 7.05023L7.05 5.63623L12 10.5862Z" fill="#2D3436"/>
						</svg>
					</div>
					<h3 className="hp-form-info-title">Requirements</h3>
				</>
			)}
			<h5 className="hp-mt-sm-56 hp-mt-32 hp-p1-body hp-font-weight-600">Photo uploading guidelines</h5>
			<List
				className="hp-mb-32"
				itemLayout="horizontal"
				dataSource={dataList}
				split={false}
				renderItem={item => (
					<List.Item>
						<List.Item.Meta
							avatar={
								<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M12 1.11816L3.65373 10L0 6.11182L1.05074 4.99365L3.65373 7.76367L10.9493 0L12 1.11816Z" fill="#2D3436"/>
								</svg>
							}
							description={item}
						/>
					</List.Item>
				)}
			/>
			<h5 className="hp-mb-12 hp-p1-body hp-font-weight-600">Good photos examples</h5>
			<Row className="hp-mb-12" style={{ gap: 20 }}>
				<Col className="hp-good-photo-with-sign">
					<div>
						<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M12 1.11816L3.65373 10L0 6.11182L1.05074 4.99365L3.65373 7.76367L10.9493 0L12 1.11816Z" fill="#ECFFE0"/>
						</svg>
					</div>
					<img src={GoodPhoto1} alt="good photo" />
				</Col>
				<Col className="hp-good-photo-with-sign">
					<div>
						<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M12 1.11816L3.65373 10L0 6.11182L1.05074 4.99365L3.65373 7.76367L10.9493 0L12 1.11816Z" fill="#ECFFE0"/>
						</svg>
					</div>
					<img src={GoodPhoto2} alt="good photo" />
				</Col>
				<Col className="hp-good-photo-with-sign">
					<div>
						<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M12 1.11816L3.65373 10L0 6.11182L1.05074 4.99365L3.65373 7.76367L10.9493 0L12 1.11816Z" fill="#ECFFE0"/>
						</svg>
					</div>
					<img src={GoodPhoto3} alt="good photo" />
				</Col>
			</Row>
			<p className="hp-photo-example-desc hp-mb-18">Full face, bright, straight, no other faces, close-up</p>
			<h5 className="hp-mb-12 hp-p1-body hp-font-weight-600">Bad photos examples</h5>
			<Row className="hp-mb-12"  style={{ gap: 20 }}>
				<Col className="hp-bad-photo-with-sign">
					<div>
						<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M11.8943 1.09847L1.08879 12L0 10.9015L10.8055 0L11.8943 1.09847Z" fill="white"/>
							<path fillRule="evenodd" clipRule="evenodd" d="M10.9112 12L0.105695 1.09847L1.19449 -4.7732e-07L12 10.9015L10.9112 12Z" fill="white"/>
						</svg>
					</div>
					<img src={BadPhoto1} alt="bad photo" />
				</Col>
				<Col className="hp-bad-photo-with-sign">
					<div>
						<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M11.8943 1.09847L1.08879 12L0 10.9015L10.8055 0L11.8943 1.09847Z" fill="white"/>
							<path fillRule="evenodd" clipRule="evenodd" d="M10.9112 12L0.105695 1.09847L1.19449 -4.7732e-07L12 10.9015L10.9112 12Z" fill="white"/>
						</svg>
					</div>
					<img src={BadPhoto2} alt="bad photo" />
				</Col>
				<Col className="hp-bad-photo-with-sign">
					<div>
						<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M11.8943 1.09847L1.08879 12L0 10.9015L10.8055 0L11.8943 1.09847Z" fill="white"/>
							<path fillRule="evenodd" clipRule="evenodd" d="M10.9112 12L0.105695 1.09847L1.19449 -4.7732e-07L12 10.9015L10.9112 12Z" fill="white"/>
						</svg>
					</div>
					<img src={BadPhoto3} alt="bad photo" />
				</Col>
			</Row>
			<p className="hp-photo-example-desc">Dark, blurry, other faces, glasses, too distant, poor lighting</p>
		</Col>
	);
}
