import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ConfigProvider } from 'antd';
import { IntlProvider } from "react-intl";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import AppLocale from './languages';

import Router from "./router/Router";
import ReactGA from "react-ga4";
import {analytics} from "../src/utils";

import {APP_ENV, GOOGLE_ANALYTICS} from './constants';

ReactGA.initialize(GOOGLE_ANALYTICS);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://712dc28fd31543d99f02cf1cde9cb0d7@o1118765.ingest.sentry.io/4504163325640704",
    integrations: [new BrowserTracing()],
    environment: APP_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}


export default function App() {
  // Redux
  const customise = useSelector(state => state.customise)

  // Lang
  const currentAppLocale = AppLocale[customise.language];

  useEffect(() => {
    analytics.page()

    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  useEffect(() => {
    document.querySelector("html").setAttribute("lang", customise.language);
  }, [customise]);

  return (
    <ConfigProvider locale={currentAppLocale.antd} direction={customise.direction}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <Router />
      </IntlProvider>
    </ConfigProvider>
  );
}
