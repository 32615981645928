import React, { useEffect, useState } from 'react';
import {Row, Col } from "antd";
import HeaderText from "../../../../layout/components/new-header/HeaderText";
import {Footer} from "../../../../layout/components/new-footer";
import MenuComponent from "./Menu";
import UserContent from "./UserContent";
import {useDeviceType} from "../../../../utils";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {getFeedback, getPersona, personaItemSelector} from "../../../../services/personaItem";
import {useParams} from "react-router-dom";
import {MenuKeys} from "./constants";
import { fetchUserBytoken } from '../../../../services/auth';
import Spinner from '../spinner';

const RecognitionPageItem = () => {
  const [isMobile] = useDeviceType();
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const [menuItem, setMenuItem] = useState(MenuKeys.FirstItem);
  const [isMenuComponent, setIsMenuComponent] = useState(true);
  const [activeTab, setActiveTab] = useState('0');
  const { persona, feedback } = useSelector(personaItemSelector);

  useEffect( () => {
    dispatch(getPersona(id));
    dispatch(getFeedback(id));
    dispatch(fetchUserBytoken());
  },[]);

  const isPersonaHasContent = 'content' in persona;

  const handleItemClick = (item) => {
    setActiveTab('0');
    setMenuItem(item);
  };

  const getMainContent = () => {
    const { contact, offer, negotiate, close } = persona.content;

    const menuContent = {
      [MenuKeys.FirstItem]: <UserContent content={contact.first_contact} feedback={feedback} activeTab={activeTab} setActiveTab={setActiveTab} menuItem={menuItem} />,
      [MenuKeys.SecondItem]: <UserContent content={contact.schedule_a_meeting} feedback={feedback} activeTab={activeTab} setActiveTab={setActiveTab} menuItem={menuItem} />,
      [MenuKeys.ThirdItem]: <UserContent content={offer.identify_prospects_needs} feedback={feedback} activeTab={activeTab} setActiveTab={setActiveTab} menuItem={menuItem} />,
      [MenuKeys.FourthItem]: <UserContent content={offer.make_a_sales_offer} feedback={feedback} activeTab={activeTab} setActiveTab={setActiveTab} menuItem={menuItem} />,
      [MenuKeys.FifthItem]: <UserContent content={negotiate.make_a_sales_pitch} feedback={feedback} activeTab={activeTab} setActiveTab={setActiveTab} menuItem={menuItem} />,
      [MenuKeys.SixthItem]: <UserContent content={negotiate.discuss_pricing} feedback={feedback} activeTab={activeTab} setActiveTab={setActiveTab} menuItem={menuItem} />,
      [MenuKeys.SeventhItem]: <UserContent content={close.remove_doubts} feedback={feedback} activeTab={activeTab} setActiveTab={setActiveTab} menuItem={menuItem} />,
      [MenuKeys.EighthItem]: <UserContent content={close.persuade_to_buy} feedback={feedback} activeTab={activeTab} setActiveTab={setActiveTab} menuItem={menuItem} />,
    }

    return (
      isMobile
        ? (
          <Row
            justify="space-between"
            className="hp-recognition-form isBlock"
          >
            {isMenuComponent ? (
              <Col lg={6}>
                <MenuComponent onItemClick={handleItemClick} changeContent={setIsMenuComponent} />
              </Col>
              ) : (
                <Col lg={18}>
                  <div className='contentBlock'>
                    <div className='mobileUserBlock'>
                      <div className='arrowBlock'>
                        <ArrowLeftOutlined onClick={() => setIsMenuComponent(true)} className='iconMenu'/>
                      </div>
                      <div>
                        <h5 className="hp-mt-10 hp-font-weight-600 hp-text-center hp-word-break userName">
                          {persona.name}
                        </h5>
                      </div>
                    </div>
                    {menuContent[menuItem as keyof typeof menuContent]}
                  </div>
                </Col>
              )
            }
          </Row>
        ) : (
          <Row
            justify="space-between"
            className="hp-recognition-form"
          >
            <Col md={6}>
              <MenuComponent onItemClick={handleItemClick} changeContent={setIsMenuComponent} />
            </Col>
            <Col md={18}>
              <div className='contentBlock'>
                {menuContent[menuItem as keyof typeof menuContent]}
              </div>
            </Col>
          </Row>
        )
    )
  }

  return !Object.keys(persona).length? (
    <Spinner />
  ) : (
    <>
      <Row className="hp-recognition-page" justify="center">
        <Col xs={24}>
          <HeaderText user />
        </Col>
      </Row>
        {isPersonaHasContent && getMainContent()}
      <Row justify="center">
        <Footer />
      </Row>
    </>
  )
}

export default RecognitionPageItem;
